<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-model="isBioDialogOpen"
    max-width="500px"
    @click:outside="$emit('update:is-bio-dialog-open', false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Editar Información de Usuario
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="userDataLocal.username"
                outlined
                dense
                label="Usuario"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="userDataLocal.name"
                outlined
                dense
                label="Nombre"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="userDataLocal.email"
                outlined
                dense
                label="Email"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="userDataLocal.address"
                outlined
                dense
                label="Dirección"
              ></v-text-field>
            </v-col>

            <template>
 
              <!-- <v-col cols="3" md="3" class="pr-0 pt-3">
                <v-select
                  id="selectorflags"
                  name="selectorflags"
                  v-model="items[0]"
                  :items="items"
                  label="Cod. Area"
                  :rules="[(v) => !!v || 'Caracteristica requerida']"
                  required
                  hide-details="auto"
                  outlined
                  dense
                  @change="changePrefix"
                ></v-select>
              </v-col> -->

              <v-col cols="12">
                <v-text-field
                  v-model="userDataLocal.phone"
                  outlined
                  dense
                  label="Teléfono"
                ></v-text-field>
              </v-col>
            </template>

            <v-col cols="12">
              <v-text-field
                v-model="userDataLocal.cuit"
                outlined
                dense
                label="Cuit"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="d-flex justify-end mt-3">
              <v-btn
                outlined
                class="me-3"
                color="secondary"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              >
                Cancelar
              </v-btn>
              <v-btn color="primary" type="submit" @click.prevent="onSubmit">
                Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from "@vue/composition-api";

export default {
  data: () => {
    return {
      // user: this.loggedUserData.id
    };
  },
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async onSubmit() {
      // console.log(this.loggedUserData.user_id)
      try {
        const form = {
          user: this.userDataLocal.user_id,
          username: this.userDataLocal.username,
          name: this.userDataLocal.name,
          email: this.userDataLocal.email,
          address: this.userDataLocal.address,
          // prefix: this.prefix,
          phone: this.userDataLocal.phone,
          cuit: this.userDataLocal.cuit
        };
        
        await this.$api.updateUser(this.userDataLocal.id, form);
        this.$emit('display-alert', {message: 'Su información fue editada correctamente', type: 'success'});
  
      } catch (e) {
        console.log(e);
      } finally {
        this.$emit("reload-data");
        this.$emit("update:is-bio-dialog-open", false);
      }
    },
    changePrefix(e) {
      const matches = e.match(/\d+/g);

      if (matches) this.prefix = Number(matches[0]);
    },
  },
  mounted() {
    // console.warn(this.userData);
    // console.log(this.userDataLocal);
  },
  computed: {
    getUserRole() {
      return this.$store.getters["session/getUserRole"]();
    },
    loggedUserData() {
      return this.$store.getters["session/userData"]();
    },
  },
  setup(props) {
    const userDataLocal = ref({});

  watch(
    () => props.isBioDialogOpen,
    () => {
      userDataLocal.value = JSON.parse(JSON.stringify(props.userData));
    }
  );

    return {
      items: [
        "🇦🇷 (+549)",
        "🇧🇷 (+55)",
        "🇧🇴 (+591)",
        "🇨🇱 (+56)",
        "🇨🇴 (+57)",
        "🇪🇨 (+593)",
        "🇲🇽 (+52)",
        "🇵🇪 (+51) ",
        "🇵🇾 (+595)",
        "🇺🇾 (+598)",
        "🇻🇪 (+58)",
      ],
      prefix: 549,
      userDataLocal,
    };
  },
};
</script>
