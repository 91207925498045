<template>
    <div>
    <v-card class="mb-4">
      <v-card-title>PERFIL</v-card-title>
    </v-card>

    <v-row class="mb-4">
      
      <v-col
        cols="12"
      > 
        <profile-bio-panel
          :user-data="userData"
          @reload-data="getUserData"
          />
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >      

      </v-col>
    </v-row>

    </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiAccountOutline, mdiLockOutline, mdiBookmarkOutline, mdiBellOutline, mdiLinkVariant } from '@mdi/js'

// import { ref } from '@vue/composition-api'
import ProfileBioPanel from '@/components/Profile/ProfileBioPanel.vue'


export default {
  components: {
    ProfileBioPanel,
  },
  data: () => {
     
    return {
      userData: {
        
      },
      userTab: null
    };

  },
  mounted() {
    this.getUserData()
  },
  methods: {
    async getUserData() {
      try {
        const response = await this.$api.getUserDataForProfile()
        response.role =response.role.label
        // response.avatar = "1.png"
        this.userData = response
        // console.log(response)
      } catch (e) {
        console.log(e);  
      }
    },
    // roleParser(role) {
    //   const roles = {
    //     0: "Vete.",
    //     1: "Admin.",
    //     2: "Prod. Agr.",
    //     3: "Cliente",
    //     4: "Tec. Estado"
    //   }
    //   return roles[role]
    // }
  },

 
  setup() {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    const tabs = [
      { icon: mdiAccountOutline, title: 'Overview' },
      { icon: mdiLockOutline, title: 'Security' },
      { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' },
    ]

    return {
      status,
      tabs,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },

}

</script>

<style lang="scss" scoped>

</style>