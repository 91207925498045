<template>
  <v-row dense class="align-center">
    <!-- user profile -->
      <v-col cols="4">
      <v-card style="height: 400px;" class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="userData.avatar ? '' : 'primary'"
            :class="userData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="userData.avatar"
              :src="require(`@/assets/images/avatars/${userData.avatar}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(userData.name) }}</span> 
          </v-avatar>

          <span class="mb-2">{{ userData.name }}</span>

          <v-chip
            label
            small
            
            :class="`v-chip-light-bg text-sm font-weight-semibold`"
          >
            {{ userData.role }}
          </v-chip>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="8">
      <v-card style="height: 400px;">
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Detalles
          </h2>

          <v-divider></v-divider>
          

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Usuario:</span>
              <span class="text--secondary">{{ userData.username || '-' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Nombre:</span>
              <span class="text--secondary">{{ userData.name || '-' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ userData.email || '-' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Rol:</span>
              <span class="text--secondary text-capitalize">{{ userData.role}}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Dirección:</span>
              <span class="text--secondary text-capitalize">{{ userData.address || '-' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">CUIT:</span>
              <span class="text--secondary text-capitalize">{{ userData.cuit || '-' }}</span>
            </v-list-item>


            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Teléfono:</span>
              <span class="text--secondary">{{ userData.phone || '-' }}</span>
            </v-list-item>

          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Editar
          </v-btn>
          <v-btn
            color="primary"
            @click="isChangePassOpen = !isChangePassOpen"
          >
            Cambiar Contraseña
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

      <!-- edit profile dialog data -->
      <profile-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
        @reload-data="$emit('reload-data')"
        @display-alert="displayAlert"
      ></profile-edit>
      
      <profile-change-pass
        :is-profile-change-pass-open.sync="isChangePassOpen"
        @display-alert="displayAlert"
      />

      <AlertPopUp ref="alert_pop_up"/>

  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import ProfileEdit from './ProfileEdit.vue'
import ProfileChangePass from './ProfileChangePass.vue'
import AlertPopUp from '../AlertPopUp.vue'

export default {
  components: {
    ProfileEdit,
    ProfileChangePass,
    AlertPopUp
},
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    
  },
  setup() {
  
    const isBioDialogOpen = ref(false)
    const isChangePassOpen = ref(false)

    //display alerts from childs
    function displayAlert(message, type){
      this.$refs.alert_pop_up.displayAlert(message, type)
    }

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,
      displayAlert,

      isBioDialogOpen,
      isChangePassOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
